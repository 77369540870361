import { AppComponent } from './app/app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicModule } from '@ionic/angular';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthenticationService } from './app/core/services/auth/authentication.service';
import { Router } from '@angular/router';
import { appInitFactory } from './app/core/initializer/initializers';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { Casino1x2TeamService } from './app/core/env-specific/1x2team/services/casino-1x2team.service';
import { CasinoBetplatformService } from './app/core/env-specific/betplatform/services/casino-betplatform.service';
import { Admin1x2TeamService } from './app/core/env-specific/1x2team/services/admin-1x2team.service';
import { AdminBetplatformService } from './app/core/env-specific/betplatform/services/admin-betplatform.service';
import { User1x2TeamService } from './app/core/env-specific/1x2team/services/user-1x2team.service';
import { UserBetplatformService } from './app/core/env-specific/betplatform/services/user-betplatform.service';
import { Content1x2TeamService } from './app/core/env-specific/1x2team/services/content-1x2team.service';
import { ContentBetplatformService } from './app/core/env-specific/betplatform/services/content-betplatform.service';
import { IEnvConfigBackofficeFrontend, IEnvApiBase } from './app/shared/models/configuration/configuration.interface';
import { ContentAbstractService, UserAbstractService, AdminAbstractService, CasinoAbstractService, GlobalAbstractService } from './app/core/env-specific/env-abstracts';
import { environment } from 'src/environments/environment';
import { PROJECT_ENV_CONFIG_TOKEN, StorageService, I18nService, USER_PERSONA_TOKEN, ProjectEnvConfig } from 'bp-angular-library';
import { AuthenticationInterceptor } from './app/core/interceptors/auth/authentication.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { GlobalBetplatformService } from './app/core/env-specific/betplatform/services/global-betplatform.service';
import { Global1x2TeamService } from './app/core/env-specific/1x2team/services/global-1x2team.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      IonicModule.forRoot({
        mode: 'md',
        rippleEffect: false,
        swipeBackEnabled: false
      }),
      IonicStorageModule.forRoot({
        name: '__appdb',
        driverOrder: [Drivers.LocalStorage]
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    { provide: PROJECT_ENV_CONFIG_TOKEN, useValue: environment },
    { provide: USER_PERSONA_TOKEN, useFactory: () => new ProjectEnvConfig('xyyz') },

    {
      provide: ContentAbstractService,
      useFactory: (projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new ContentBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Content1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: UserAbstractService,
      useFactory: (projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new UserBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new User1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: AdminAbstractService,
      useFactory: (projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new AdminBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Admin1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: CasinoAbstractService,
      useFactory: (projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new CasinoBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Casino1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: GlobalAbstractService,
      useFactory: (projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new GlobalBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Global1x2TeamService();
        }
        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [Router, StorageService, AuthenticationService, I18nService, ContentAbstractService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch(err => console.error(err));
