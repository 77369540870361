import { Injectable } from '@angular/core';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { UserAbstractService } from '../../env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class User1x2TeamService extends UserAbstractService {
  loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    throw new Error('Method not implemented.');
  }
  refreshToken(): Promise<Partial<IUserDetails> | null> {
    throw new Error('Method not implemented.');
  }
  getUserProfile(): Promise<Partial<IUserDetails> | null> {
    throw new Error('Method not implemented.');
  }
  updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    throw new Error('Method not implemented.');
  }
}
