import { Injectable } from '@angular/core';

import { ContentAbstractService } from '../../env-abstracts';
import { Routes } from '@angular/router';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { ALL_ROUTES_FOR_BETPLATFORM, SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM } from '../routes/routes.default';

@Injectable({
  providedIn: 'root'
})
export class ContentBetplatformService extends ContentAbstractService {
  buildAllRoutes(): Routes {
    return ALL_ROUTES_FOR_BETPLATFORM;
  }
  buildSidemenuRoutes(): Partial<INavGroup>[] {
    return SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM;
  }
}
