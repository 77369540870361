import { inject, Injectable } from '@angular/core';
import { UserAbstractService } from '../../env-abstracts';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { BetPlatformApiAdapterService } from 'bp-angular-library';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';
import { bpMergeUserDetails } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';

@Injectable({
  providedIn: 'root'
})
export class UserBetplatformService extends UserAbstractService {
  private translateService: TranslateService = inject(TranslateService);
  private authService: AuthenticationService = inject(AuthenticationService);
  private bpApiAdapterService: BetPlatformApiAdapterService = inject(BetPlatformApiAdapterService);

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const loginPayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userLoginWithUsernameAndPassword(username, password);
        await this.authService.userAuthChanged(loginPayload.data);
        return resolve(loginPayload.data);
      } catch (error) {
        // TODO: Check if we can present error message instead of presenting custom message without any context.
        // TODO: Revisit error handling and error messages in the entire environment adapter
        return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentialsOrTryLater')));
      }
    });
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const refreshToken: string | undefined = await this.authService.user$.value?.auth?.refreshToken;

        if (!refreshToken) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveRefreshToken')));
        }

        const loginPayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userRefreshToken(refreshToken);
        await this.authService.userAuthChanged(loginPayload?.data);
        const getProfilePayload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userGetProfile();
        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;
        const mergedValue: Partial<IUserDetails> | null = bpMergeUserDetails(currentDetails, getProfilePayload?.data);
        await this.authService.userAuthChanged(mergedValue);
        return resolve(mergedValue);
      } catch (error: unknown) {
        return reject(error);
      }
    });
  }
  // TODO: Revisit naming of this method. It should be more descriptive. Everyone are 'user' but we also have 'player' and 'profile' in the same context
  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const payload: IApiPayload<Partial<IUserDetails>> = await this.bpApiAdapterService.userGetProfile();
        return resolve(payload.data);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    const freshData: Partial<IUserDetails> | null = await this.getUserProfile();
    await this.authService.userDetailsChanged(freshData);
    return this.authService.user$.value;
  }
}
