import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, concatMap, filter, finalize, switchMap, take } from 'rxjs/operators';

import { ILoginCredentials } from 'bp-framework/dist/auth/auth.interface';

import { PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';

import { UserAbstractService } from '../../env-specific/env-abstracts';

import { AuthenticationService } from '../../services/auth/authentication.service';

import { BehaviorSubject, from, Observable, Subject, throwError } from 'rxjs';
import { ApiProvider, IEnvApiBase, IEnvConfigBackofficeFrontend } from 'src/app/shared/models/configuration/configuration.interface';
import { STORAGE_KEYS } from 'src/app/shared/models/storage/storage.const';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase> = inject<IEnvConfigBackofficeFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);
  private authService: AuthenticationService = inject(AuthenticationService);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private storageService: StorageService = inject(StorageService);

  private acceptableUrls: string[] = this.projectConfig?.httpInterceptors?.attachAuthTokenToTheseUrls;
  private unacceptableUrls: string[] = this.projectConfig?.httpInterceptors?.doNotAttachAuthTokenToTheseUrls;
  private skip401HandlingForTheseUrls: string[] = this.projectConfig?.httpInterceptors?.skip401HandlingForTheseUrls;

  private isRefreshing = false;
  private refreshTokenSubject$: Subject<any> = new Subject<any>();
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private requestQueue: HttpRequest<any>[] = [];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isThisACallToAcceptableUrl: boolean = this.acceptableUrls.some((url: string): boolean => request.url.startsWith(url));

    if (!isThisACallToAcceptableUrl) {
      // Ukoliko poziv nije upucen nasem APIu, onda ne treba da radimo nista
      return next.handle(request);
    }

    const isThisACallToUrlWeWantToSkip: boolean = this.unacceptableUrls.some((url: string): boolean => request.url.startsWith(url));

    if (isThisACallToUrlWeWantToSkip) {
      // Ako je poziv upucen API endpointu koji treba da uloguje korisnika, onda tu nije potreban token u headeru
      return next.handle(request);
    }

    if (request.headers.has('Authorization')) {
      // Ako vec postoji header, onda nije potrebno nista da dodajemo
      return next.handle(request);
    }

    if (this.authService.rawToken$.value) {
      // U slucaju da token postoji, onda mozemo da ga dodamo u header
      request = request.clone({
        setHeaders: {
          Authorization: this.provideTokenValue(this.projectConfig?.api?.provider)
        }
      });
    }

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse): Observable<any> => {
        const skip401Handling: boolean = this.skip401HandlingForTheseUrls.some((url: string): boolean => request.url.startsWith(url));

        if (skip401Handling) {
          // If it's the call we want to skip (eg. refresh token request), rethrow the error without handling it
          return throwError(() => errorResponse);
        }

        return this.handle401Error(errorResponse, request, next);
      })
    );
  }

  private provideTokenValue(apiProvider: ApiProvider): string {
    switch (apiProvider) {
      case '1x2team':
        return `token ${this.authService.rawToken$.value}`;
      case 'betplatform':
        return `Bearer ${this.authService.rawToken$.value}`;
      default:
        return '';
    }
  }

  private addTokenHeader(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.set('Authorization', this.provideTokenValue(this.projectConfig?.api?.provider))
    });
  }

  /**
   * @function handle401Error
   * @description Sadrzi logiku koja ce ukoliko uslovi dozvoljavaju, pokusati da ponovo uloguje korisnika ako je greska vezana za "401 Unauthorized"
   * @param {HttpErrorResponse} errorResponse - Greska koja je dobijena iz prvog poziva koji je neuspesno zavrsen
   * @param {HttpRequest} request - HTTP zahtev koji se salje
   * @param {HttpHandler} next - HTTP handler za poziv koji cemo modifikovati i ponovo poslati
   * @returns {Observable<any>}
   */
  private handle401Error(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.projectConfig?.features?.storeCredentials === true) {
      // TODO: In here we should consider having combination of conditions. It is not only if the 'storeCredentials' is true, but we should also check what 'provider' is it
      return this.tryWithCredentialsFromMemory(errorResponse, request, next);
    } else if ((this.projectConfig?.api?.provider as ApiProvider) === '1x2team') {
      return this.handle401ErrorFor1x2TeamApi(errorResponse, request, next);
    } else if ((this.projectConfig?.api?.provider as ApiProvider) === 'betplatform') {
      return this.handle401ErrorForBetPlatformApi(errorResponse, request, next);
    } else {
      return throwError(() => errorResponse);
    }
  }

  private tryWithCredentialsFromMemory(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Pozivi koji nisu vezani za "Unauthorized", ne treba da se ponavljaju
    if (errorResponse?.status !== 400 && errorResponse?.status !== 401) {
      return throwError(() => errorResponse);
    }

    // Ako je poziv upucen API endpointu koji treba da uloguje korisnika, onda ne treba da se ponavlja (zelimo da izbegnemo loop)
    const isThisACallToUrlWeWantToSkip: boolean = this.unacceptableUrls.some((url: string): boolean => typeof errorResponse?.url === 'string' && errorResponse?.url?.startsWith(url));

    if (isThisACallToUrlWeWantToSkip) {
      return throwError(() => errorResponse);
    }

    if (!this.isRefreshing) {
      // Ukoliko proces osvezavanja tokena nije zapocet, onda mozemo da krenemo u taj proces. Ukoliko jeste, ovaj deo koda se preskace
      this.isRefreshing = true;
      this.refreshTokenSubject$.next(null);

      // Prvo cemo pokusati da ulogujemo korisnika sa kredencijalima koji se cuvaju u localstorage i nakon toga bi trebalo da dobijemo novi token
      // TODO: If the user never signed in, then this will fail. IMPORTANT: Consider what should happen when the user never signed before and we don't have credentials in memory. We should show some toast or something like that
      return from(this.storageService.getLocalItem(STORAGE_KEYS.userCredentials)).pipe(
        concatMap((credentials: ILoginCredentials | null) => {
          return this.userAbstractService.loginWithUsernameAndPassword(credentials?.username || '', credentials?.password || '');
        }),
        switchMap((value: any) => {
          // Ako uspesno dobijemo novi token, onda mozemo da ga dodamo u header poziva koji je prethodno neuspesno zavrsen
          this.isRefreshing = false;
          this.refreshTokenSubject$.next(this.authService.rawToken$.value);

          return next.handle(this.addTokenHeader(request));
        }),
        catchError(error => {
          // U slucaju greske, ili bezuspesnog dobijanja novog tokena, vraticemo gresku
          this.isRefreshing = false;

          // TODO: IMPORTANT: Revisit this logic here. Because we have different api providers and they handle errors differently, we might need to have better logic when the user should be logged out
          this.authService.logout(true);

          return throwError(() => error);
        })
      );
    } else {
      // Ako je proces osvezavanja tokena odradjen, onda mozemo da novi token dodamo u header poziva koji je prethodno neuspesno zavrsen
      return this.refreshTokenSubject$.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(() => next.handle(this.addTokenHeader(request)))
      );
    }
  }

  private handle401ErrorFor1x2TeamApi(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // TODO: Important - Check how to handle case when token expires on 1x2team backend.. we need to perform some silent login in background
    // TODO: Also, since we can't save user credentials on the system, we might want to save those credentials in mamery so while the application on 1x2team environment is running, we can perform silent login
    // TODO: Check what to do with token refresh for the 'betplatform' environment
    return throwError(() => errorResponse);
  }

  private handle401ErrorForBetPlatformApi(errorResponse: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // TODO: Revisit the logic for the refresh token. Ensure that the process is done correctly
      // TODO: IMPORTANT: Check if it is possible to gather all of the requests that are made while the token is being refreshed and then replay them
      return from(this.userAbstractService.refreshToken()).pipe(
        switchMap((value: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(value);

          // Replay all requests that were queued
          this.requestQueue.forEach(req => next.handle(this.addTokenHeader(req)).subscribe());
          this.requestQueue = [];

          return next.handle(this.addTokenHeader(request));
        }),
        catchError(error => {
          this.isRefreshing = false;
          this.requestQueue = [];

          if (errorResponse.status === 401) {
            // We want to logout the user if the token is expired and to clear the token from the storage
            // TODO: Check if we should show some message to the user or redirect him to some page
            this.authService.logout(true);
          }

          return throwError(() => errorResponse);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      // Queue the requests while the token is being refreshed
      this.requestQueue.push(request);

      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => next.handle(this.addTokenHeader(request)))
      );
    }
  }
}
