import { Component } from '@angular/core';
import { OUTLET_ANIMATION } from './shared/animations/animations';
import { SidemenuComponent } from './features/sidemenu/sidemenu.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'ion-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [OUTLET_ANIMATION],
  standalone: true,
  imports: [IonicModule, SidemenuComponent]
})
export class AppComponent {
  title = 'bp-back-office';
  sidePaneState!: boolean;
}
