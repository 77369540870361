import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { PROJECT_ENV_CONFIG_TOKEN, ThemeToggleComponent } from 'bp-angular-library';
import { IEnvApiBase, IEnvConfigBackOffice } from 'bp-framework/dist/configuration/configuration.interface';
import { PACKAGE_DETAILS } from 'src/environments/package-details';
import { ContentAbstractService } from 'src/app/core/env-specific/env-abstracts';
import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

@Component({
  selector: 'app-sidemenu',
  standalone: true,
  imports: [CommonModule, IonicModule, RouterLink, RouterLinkActive, ThemeToggleComponent],
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  public appVersion: any = PACKAGE_DETAILS.version;
  private destroyRef: DestroyRef = inject(DestroyRef);
  public projectConfig: IEnvConfigBackOffice<IEnvApiBase> = inject<IEnvConfigBackOffice<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);
  private contentAbstractService: ContentAbstractService = inject(ContentAbstractService);
  public authService: AuthenticationService = inject(AuthenticationService);
  private router: Router = inject(Router);
  private menuCtrl: MenuController = inject(MenuController);

  public sideMenuGroups: Partial<INavGroup>[] = [];

  //#region lifecycle hooks
  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.sideMenuGroups = this.contentAbstractService.buildSidemenuRoutes();
    console.log('sideMenuGroups ', this.sideMenuGroups);
  }
  //#endregion

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd)
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((event: any): void => this.resolveCurrentSegment(event?.urlAfterRedirects));
  }

  private resolveCurrentSegment(urlAfterRedirect: string): void {
    let matchingNavItem: Partial<INavGroup> | null = null;

    this.sideMenuGroups?.forEach((item: Partial<INavGroup>) => {
      if (item.path && urlAfterRedirect.includes(item.path)) {
        matchingNavItem = item;
        matchingNavItem.expanded = Array.isArray(item?.children) && item?.children?.length > 0;
      }
    });
  }

  public closeMenu(): void {
    this.menuCtrl.close('primary-sidemenu');
  }

  public async logout(): Promise<void> {
    await this.authService.logout(true);
  }

  public goToLogin(): void {
    this.router.navigate(['/login']);
  }
}
