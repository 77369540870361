import { Injectable } from '@angular/core';
import { GlobalAbstractService } from '../../env-abstracts';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';

import { ITransactionGroup } from 'bp-framework/dist/transactions/transaction-groups.interface';

@Injectable({
  providedIn: 'root'
})
export class Global1x2TeamService extends GlobalAbstractService {
  financeGetTransactionGroups(): Promise<IApiPayload<ITransactionGroup[]> | null> {
    throw new Error('Method not implemented.');
  }
}
