import { inject, Injectable } from '@angular/core';
import { AdminAbstractService } from '../../env-abstracts';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ITransactionDetails, TransactionType } from 'bp-framework/dist/transactions/transactions.interface';
import { IApiPayload, IApiRequestParams } from 'bp-framework/dist/api/api.interface';

import { BetPlatformApiAdapterService } from 'bp-angular-library';
import { IJackpotDetailsForAdmin } from 'bp-framework/dist/casino/casino.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminBetplatformService extends AdminAbstractService {
  public transactions: ITransactionDetails[] = [];
  private bpApiAdapterService: BetPlatformApiAdapterService = inject(BetPlatformApiAdapterService);

  adminRegisterPlayer(username: string, password: string, language: string, phone: string): Promise<any> {
    return this.bpApiAdapterService.adminRegisterPlayer(username, password, language, phone);
  }

  adminGetPlayersList(params: IApiRequestParams): Promise<IApiPayload<Partial<IUserDetails>[]>> {
    // TODO: Implement pagination
    return this.bpApiAdapterService.adminGetPlayersList(params);
  }

  adminGetPlayerById(playerId: string): Promise<IApiPayload<Partial<IUserDetails> | null>> {
    return this.bpApiAdapterService.adminGetPlayerById(playerId);
  }

  adminMakePlayerDeposit(playerId: string, amount: number): Promise<IApiPayload<Partial<ITransactionDetails> | null>> {
    return this.bpApiAdapterService.adminMakePlayerDeposit(playerId, amount);
  }

  adminMakePlayerWithdrawal(playerId: string, amount: number): Promise<IApiPayload<Partial<ITransactionDetails> | null>> {
    return this.bpApiAdapterService.adminMakePlayerWithdrawal(playerId, amount);
  }

  adminBlockPlayer(playerId: string): Promise<IApiPayload<unknown>> {
    return this.bpApiAdapterService.adminBlockPlayer(playerId);
  }
  adminUnblockPlayer(playerId: string): Promise<IApiPayload<unknown>> {
    return this.bpApiAdapterService.adminUnblockPlayer(playerId);
  }

  adminAwardJackpot(playerId: string, jackpotId: number): Promise<IApiPayload<unknown>> {
    return this.bpApiAdapterService.adminAwardJackpot(playerId, jackpotId);
  }

  adminGetJackpotsList(params: IApiRequestParams): Promise<IApiPayload<IJackpotDetailsForAdmin[]>> {
    return this.bpApiAdapterService.adminGetJackpotsList(params);
  }

  adminGetTransactions(startDateIso: string, endDateIso: string, limit: number, offset: number, transactionTypes: TransactionType[], userIds?: string[]): Promise<IApiPayload<Partial<ITransactionDetails>[]>> {
    return this.bpApiAdapterService.adminGetTransactions(startDateIso, endDateIso, limit, offset, transactionTypes, userIds || []);
  }
}
