import { IEnvConfigBackofficeFrontend } from 'src/app/shared/models/configuration/configuration.interface';

import { PROJECT_PREFIX } from 'bp-framework/dist/storage/storage.const';
import { IEnvApiOnBetPlatform } from 'bp-framework/dist/env-specific/betplatform/configuration/configuration.interface';

export const BASE_URLS_PROD = {
  default: 'https://bp.app.tasp13.com'
};

export const environment: IEnvConfigBackofficeFrontend<IEnvApiOnBetPlatform> = {
  production: true,
  environmentName: 'prod',
  projectName: 'bp-backoffice-frontend',
  projectPrefix: PROJECT_PREFIX.backOfficeApp,

  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_PROD.default],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_PROD.default}/auth/login`],
    skip401HandlingForTheseUrls: [`${BASE_URLS_PROD.default}/auth/refresh`]
  },
  api: {
    provider: 'betplatform',
    baseUrls: {
      default: BASE_URLS_PROD.default
    },
    coreAuthEndpoints: {
      playerLogin: '',
      adminLogin: '',
      getProfile: ''
    }
  },
  features: {
    frontendMode: 'default',
    storeCredentials: false
  }
};
