import { I18nService, PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';

import { Router, Routes } from '@angular/router';

import { AuthenticationService } from '../services/auth/authentication.service';
import { ContentAbstractService } from '../env-specific/env-abstracts';
import { IEnvApiBase, IEnvConfigBackofficeFrontend } from 'src/app/shared/models/configuration/configuration.interface';
import { inject } from '@angular/core';
import { SUPPORTED_LANGUAGE_OPTIONS } from 'src/app/shared/models/i18n/i18n.const';

export function appInitFactory(router: Router, storageService: StorageService, authService: AuthenticationService, i18nService: I18nService, contentAbstractService: ContentAbstractService): () => Promise<boolean> {
  const projectConfig: IEnvConfigBackofficeFrontend<IEnvApiBase> = inject<IEnvConfigBackofficeFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  return () =>
    new Promise(async (resolve, reject) => {
      try {
        await storageService.initializeStorageService(projectConfig.projectPrefix);
        await authService.handleAppInitialization();
        await i18nService.initializeLanguage(SUPPORTED_LANGUAGE_OPTIONS);

        const appRoutes: Routes = contentAbstractService.buildAllRoutes();
        router.resetConfig(appRoutes);

        resolve(true);
      } catch (error) {
        console.error('Failed to initialize the app:', error);
        reject(error);
      }
    });
}
