import { inject, Injectable } from '@angular/core';

import { CasinoAbstractService } from '../../env-abstracts';

import { BetPlatformApiAdapterService } from 'bp-angular-library';

@Injectable({
  providedIn: 'root'
})
export class CasinoBetplatformService extends CasinoAbstractService {
  private bpApiAdapterService: BetPlatformApiAdapterService = inject(BetPlatformApiAdapterService);
}
